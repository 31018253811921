
import { EntryBody } from "../components/Entry/EntryBody";

export const cover: { [id: string]: any } = {

    id: "cover",
    title: <>Sigueu benvingudes!</>,
    date: "7 Oct 2024",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
This is my cybernetic space.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Chapter . is a collection of typewritten delirious thoughts had during summer 2022.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Chapter I corresponds roughly to my first year of PhD. Random ideas on language models, some on generics and World too.

</div>,

<div className="font-mono">
The order is not linear in time, and there are some interactive entries, so my recommendation is to scroll top to bottom and stop to read when one finds an entry they like.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Chapter II is the new stuff!! Hot of the press, on generics and language games!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Thanks for visiting & enjoy!

</div>,

      ]}
    />
  ),
};
