import { Entry } from "../../components/Entry/Entry";
import { JournalIndexHover } from "../../components/JournalIndexHover/JournalIndexHover";
// ENTRIES
import { lorem_ipsum } from "../../entries/lorem_ipsum";
import { useParams } from "react-router-dom";
import { talking_and_walking } from "../../entries/talking_and_walking";
import { the_antimony_of_language_models } from "../../entries/the_antimony_of_language_models";
import { now_generating_neuron } from "../../entries/now_generating_neuron";
import { generics_as_auxiliary_positions } from "../../entries/generics_as_auxiliary_positions";
import { negation_and_generics } from "../../entries/negation_and_generics";

export const entries = [
  negation_and_generics,
  the_antimony_of_language_models,
  generics_as_auxiliary_positions,
  now_generating_neuron,
  talking_and_walking,
];

export const Chapter_Two = () => {
  // if path is /entry/:entryId, show only that entry
  // else show all entries
  let { entryId } = useParams();
  // check if entryId is not undefined
  let singleEntry;
  entryId
    ? (singleEntry = entries.find((entry) => entry.id === entryId))
    : (singleEntry = entries[0]);

  return (
    <div className="flex flex-col">
      <div>
        <JournalIndexHover entries={entries} chapter="chapter_two" />
      </div>
      <div>
        {entryId ? (
          <div className="flex flex-col items-center w-full h-full  p-5 pr-2 pl-2 sm:pr-5 sm:pl-5 space-y-1">
            <Entry
              title={singleEntry ? singleEntry.title : "Lorem Ipsum"}
              date={singleEntry ? singleEntry.date : "now!"}
              Body={singleEntry ? singleEntry.Body : <>"Lorem Ipsum"</>}
            />
          </div>
        ) : (
          <div className="flex flex-col items-center w-full h-full p-5 pr-2 pl-2 sm:pr-5 sm:pl-5 space-y-10 ">
            {/*loop through the entries array to create an entry for each element*/}
            {entries.map((entry, index) => (
              <Entry title={entry.title} date={entry.date} Body={entry.Body} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
