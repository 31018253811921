
import { EntryBody } from "../components/Entry/EntryBody";

export const negation_and_generics: { [id: string]: any } = {

    id: "negation_and_generics",
    title: <>Words we use for Generics are also what we Negate of Individuals</>,
    date: "2/3/10 Dec 2024",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
[verbatim transcription from notebook]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
so I see 'mosquitoes have wings' & 'mosquitoes carry malaria' but what about 'mosquitoes are cool'?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
also related to non-charactericity what happens when the generic is defeated? that that, is made explicit

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
if i get bit by a mosquito in my trip to Africa & I"m explaining it to a friend, I will say 'but I didn"t get malaria!' same about saying that penguins do not fly

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
because when explaining a situation when something striking could have happened, we always make the negation explicit 'I met a tiger in the jungle but I was fine'

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
'books are paperbacks' is 'false' one would not negate that the book is not paperback (this is the positive counterinstances thing in Leslies theory)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
so the properties that _define_ a kind are related to what we are ready to negate

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
as per the frame problem, we do not negate the tings that are not, because it is (infinite?), so there is meaning in negating a property

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
same with artifacts, if the Orangecrusher is clearly defective and does not crush oranges we will say: 'it does not crush oranges', even if it does not crush pears either

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
so what is a generic property? a property we would negate as opposed to saying another property OR not negating it if it is not present

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
when seeing a car one will not say 'it"s not red' rather than its blue, whereas one may say 'this taxi is not black & yellow' if one is from BCN. one will never say 'this car doesn"t have seahorse imprints'

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
'ducks lay eggs' i think could be analyzed not in terms of individuals but rather of groups/societies of ducks, in which case the farmer will say 'i am going bankrupt because my ducks refuse to lay eggs'

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
what does then mean to negate a property of the kind in an instantiation?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
a dog-owner will say 'don"t worry ahe doesn"t bite' upon encountering a kid on the street, we take 'dogs bite'/'rottweilers maul people' as true/acceptable

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
---

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
in the case of penguins i think one should interpret penguins as an "instantiation" of birds, so in that sense birds is a kind of kinds rather than a kind of individuals. then saying penguins don"t fly is related to birds fly, but 'this penguin doesnt fly' sounds way stranger, because 'penguins don"t fly' is the salient kind/generic, as opposed to birds fly

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
---

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The previous put a bit better: what we accept as a generic is what we would _negate_ of something in a description language game. This depends on the web of theory of the context, as I may accept "female diving beetles have striped elytra" which may lead me to express "this female diving beetle has no/few stripes on her elytra" when describing just such a thing, of course this it not something one without knowledge on aquatic beetles can perform, they will just say "this beetle is black".

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
It is curious how this relates the very raw metalinguistic interpretation of the generic as being a sort of metalinguistic indicator for "these two words go together" rather than having any metaphysical implication (as a relationship between kind, individual and property). Because of leslies observation of the lack of positive counterinstances, the word that describes the property _will_ be relevant on the situation where the individual word is pronounced, either as a (mostly implicit) commitment to the generic or an explicit negation (because there is no salient characteristic to name instead of negating the "generic" one).

</div>,

      ]}
    />
  ),
};
